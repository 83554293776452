.topButton {
  height: 45px;
  width: 45px;
  position: fixed;
  bottom: 40px;
  right: 20px;
  z-index: 900;
  transition: visibility 0.2s linear, opacity 0.2s linear;
}

@media screen and (min-width: 768px) {
  .topButton {
    height: 60px;
    width: 60px;
    right: 20px;
  }
}

@media screen and (min-width: 1025px) {
  .topButton {
    height: 55px;
    width: 55px;
    bottom: 36px;
    right: 33px;
  }

  .topButton:hover {
    cursor: pointer;
  }
}

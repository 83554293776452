/* Cookies Page */
.Policy-Content-Container {
  padding-left: 25px;
  padding-right: 25px;
}

.PolicyPage-Title {
  color: #cccccc;
  text-align: center;
  font-size: 13pt;
  font-weight: 800;
  font-family: montserratExtraBold;
  padding-bottom: 20px;
  margin-top: 16px;
}

.PolicyPage-Content {
  color: #303030;
  line-height: 2;
  font-size: 8pt;
  font-family: montserratRegular;
  padding-bottom: 10px;
}

.Policy-Content-Container p,
.Policy-Content-Container h1,
.Policy-Content-Container h2,
.Policy-Content-Container h3,
.Policy-Content-Container ul {
  margin: 0;
}

.Policy-Content-Container a {
  word-wrap: break-word;
}

.Policy-Content-Container h1 {
  font-size: 11pt;
  font-family: montserratBold;
}

.Policy-Content-Container h2 {
  font-size: 9pt;
  font-family: montserratBold;
}

.Policy-Content-Container h3 {
  font-size: 9pt;
  font-family: montserratBold;
  font-style: italic;
}

@media screen and (min-width: 1025px) {
  .Policy-Content-Container {
    margin: auto;
    width: 920px !important;
    /* margin-top: 68px; */
    /* overflow: visible; */
  }
  .PolicyPage-Title {
    font-size: 34pt;
    padding-bottom: 3px;
    margin-top: 57px;
  }
  .PolicyPage-Content {
    font-size: 12pt;
    padding-bottom: 230px;
  }
  .Policy-Content-Container h1 {
    font-size: 16pt;
  }
  .Policy-Content-Container h2 {
    font-size: 12pt;
  }
  .Policy-Content-Container h3 {
    font-size: 12pt;
  }
}
.PolicyPage-Content ul,
.PolicyPage-Content li {
  font-family: "PT Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

.PolicyPage-Content a {
  color: #1279be;
}/*# sourceMappingURL=CookiesPolicyPage.css.map */
/* card component */
.sixteen-nine-img {
  display: block;
  position: relative;
  padding-top: 56.25%;
}

.sixteen-nine-img img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: left;
     object-position: left;
}

.landing-card-wrapper {
  display: none;
}

.landing-card-link,
.result-card-link {
  text-decoration: none;
  cursor: pointer;
}

.landing-card-image,
.result-card-image {
  width: 100%;
  cursor: pointer;
}

.landing-card-title {
  font-size: 20px;
  padding: 0px 0px 3px;
  text-align: left;
}

.result-card-title {
  font-family: MStiffHeiBold;
  font-size: 22px;
  line-height: 32px;
  margin: 5px 0px;
}

@media screen and (min-width: 1025px) {
  .landing-card-wrapper,
  .result-card-wrapper {
    display: block;
  }
  .landing-card-wrapper {
    /* use its parent layout to contrain 270px later */
    /* width: 270px; */
    width: auto !important;
    /* padding: 8px 0px 30px 8px; */
    padding: 0 8px 8px 8px;
  }
  .Other-Item-Div .landing-card-wrapper {
    height: 232px;
    box-sizing: border-box;
  }
  .landing-card-title {
    font-size: 19px;
    margin: 12px 0px;
  }
  .Other-Item-Div .landing-card-title,
  .beauty .landing-card-title {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .result-card-wrapper {
    width: 100%;
  }
  .result-card-title {
    font-size: 22px;
    margin: 8px 0px;
  }
  .First-Item {
    font-size: 23px !important;
    margin: 16px 0px !important;
  }
}/*# sourceMappingURL=Card.css.map */
.Result-Content-Container {
  display: grid;
  -moz-column-gap: 30px;
       column-gap: 30px;
  row-gap: 20px;
  grid-template-columns: repeat(1, 1fr);
  padding: 3px 25px 3px 25px;
}

.Result-Article-Container {
  width: 286.6666666667;
  word-wrap: break-word;
}

@media screen and (min-width: 768px) {
  .Result-Content-Container {
    padding: 3% 3% 3% 3%;
  }
}
@media screen and (min-width: 1025px) {
  .Result-Content-Container {
    grid-template-columns: repeat(3, 1fr);
    padding: 0;
  }
}
.Result-Content-Container > .sixteen-nine {
  display: block;
  position: relative;
}

.Result-Content-Container > .result-card-title {
  font-size: 18px;
  line-height: 32px;
  margin: 5px 0px;
}

@media screen and (min-width: 1025px) {
  .Result-Content-Container > .result-card-title {
    font-size: 22px;
    margin: 8px 0px 0px 0px;
  }
  .Result-Content-Container > .Read-More {
    font-size: 15px;
    padding: 0px 8px;
  }
}
.Result-Content-Container > .Result-Post-Excerpt {
  font-family: montserratLight, "MHei HK";
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
  color: #777;
}

.Result-Content-Container > .Read-More {
  font-family: montserratMedium;
  font-style: italic;
  font-size: 13px;
  font-weight: 300 !important;
  text-decoration: underline;
  padding: 8px 0px 0px 0px;
}/*# sourceMappingURL=ResultPageSkeleton.css.map */
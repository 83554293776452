/* Footer */
$title-font-family: montserratBold;
$subtitle-font-family: montserratRegular;
.Footer-Container {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: auto;
  background-color: #1a1a1a;
}

.Footer-Content {
  width: auto;
  margin: auto;
  display: flex;
  padding: 20px;
  box-sizing: border-box;
}

.Grid-Container {
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.Footer-Title {
  font-family: $title-font-family;
  font-size: 2.8vw;
}

.Footer-Subtitle {
  font-family: $subtitle-font-family;
  color: white;
  // font-size: 1.8vw;
  font-size: 1.6vw;
  display: block;
  margin: 1em 0em !important;
}

@media screen and (min-width: 768px) {
  .Footer-Content {
    padding: 40px;
  }
}

@media screen and (min-width: 1025px) {
  .Footer-Container {
    height: auto;
    padding: 70px 0px 100px 0px;
  }
  .Footer-Content {
    padding: 0;
  }

  .Footer-Title {
    font-size: 18px;
  }

  .Footer-Subtitle {
    font-size: 16px;
    margin: 1em 0em !important;
  }
}

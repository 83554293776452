/* LatestSwiper */
.Latest-Swiper {
  margin: 0;
  padding: 0;
  min-height: 720px;
  position: relative;
  margin: auto;
}

.swiper-wrapper {
  height: 100%;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  perspective: 1000;
  -webkit-perspective: 1000;
  transform: translateZ(0);
}

.swiper-slide {
  position: relative;
  overflow: visible;
  /* swiper height */
  height: auto !important;
}

.swiper {
  width: 100%;
  height: 100%;
}

/* disable default Swiper prev/next button */
.swiper-button-prev::after,
.swiper-button-next::after {
  display: none;
}

@media screen and (min-width: 1025px) {
  .Category-Swiper-Section-Mobile {
    display: none !important;
  }
  .swiper-button-prev,
  .swiper-button-next {
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
    padding: 4px;
  }
  .swiper-button-prev {
    background-image: url("../../assets/img/icon-01.png");
  }
  .swiper-button-next {
    background-image: url("../../assets/img/icon-02.png");
  }
}
/* overriding default Swiper pagination bullets */
.swiper-pagination-bullet {
  background-image: url("../../assets/img/icon-04.png");
}

.swiper-pagination-bullet-active {
  background: #000 !important;
  background-image: url("../../assets/img/icon-05.png");
}

.Latest-Swiper-Custom-Pagination {
  display: none;
}

@media screen and (min-width: 1025px) {
  /* make pagination under the image */
  .Latest-Swiper-Custom-Pagination {
    display: block;
    position: absolute !important;
    top: 550px !important;
    right: 69px !important;
    text-align: right;
    width: auto !important;
  }
}
.Today {
  margin-left: 25px;
  margin-right: 25px;
  text-transform: uppercase;
}

.Day {
  font-family: ITCMedium;
  font-weight: 600;
  margin-top: 16px;
  font-size: 40px;
  line-height: 41px;
}

.Month {
  font-family: ITCExtraLight;
  font-weight: 200;
  font-size: 27px;
  line-height: 41px;
}

@media screen and (min-width: 1025px) {
  .Today {
    display: flex;
    text-align: left;
    margin-left: 69px;
  }
  .Day,
  .Month {
    margin: 0;
    font-size: 50px;
    line-height: 1.2;
    margin-top: 12px;
  }
}
.Latest-Swiper-Title-Wrapper {
  display: flex;
  justify-content: start;
  /* margin-bottom: 1vw; */
}

.Latest-Swiper-Title {
  font-family: ITCMedium, MStiffHeiMedium;
  font-weight: 500;
}

.Latest-Swiper-Title-Mobile {
  width: auto;
  font-size: 29px;
  line-height: 1.6;
  margin-top: 12px;
  margin-bottom: 0;
}

.Latest-Swiper-Image-Title-PC {
  font-size: 32px;
  margin-bottom: 0;
  padding: 14px;
  padding-top: 21px;
  line-height: 1;
  margin: 0;
  color: white;
  display: none;
}

@media screen and (min-width: 1025px) {
  .Latest-Swiper-Title-Mobile {
    display: none;
  }
  .Latest-Swiper-Image-Title-PC {
    display: block;
  }
}
.Latest-Swiper-Image-Wrapper {
  /* width: 100vw; */
  /* height: 100vw; */
  margin: auto;
  overflow: hidden !important;
  position: relative;
  cursor: pointer;
}

.Latest-Swiper-Image {
  width: 100vw !important;
}

@media screen and (min-width: 1025px) {
  .Latest-Swiper-Image-Wrapper {
    width: 85%;
    /* make image 16:9 */
    height: 439.875px;
    overflow: hidden;
  }
  .Latest-Swiper-Image {
    width: 100% !important;
    -o-object-fit: cover;
       object-fit: cover;
  }
}
.Latest-Swiper-Image-Bar {
  width: 100%;
  display: none;
}

@media screen and (min-width: 1025px) {
  .Latest-Swiper-Image-Bar {
    display: block;
  }
}
.Bottom-Left {
  position: absolute;
  left: 0;
  bottom: 0;
}

.Latest-Swiper-Desc-Wrapper {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin-bottom: 20px;
}

@media screen and (min-width: 1025px) {
  .Latest-Swiper-Desc-Wrapper {
    width: 85%;
    margin: auto;
    justify-content: start;
  }
}
.Latest-Swiper-Category-Img-Mobile-Wrapper {
  width: 40%;
  position: relative;
}

.Latest-Swiper-Category-Img-Mobile {
  position: absolute;
  bottom: 0;
  width: 100%;
  margin: auto;
}

@media screen and (min-width: 1025px) {
  .Latest-Swiper-Category-Img-Mobile-Wrapper {
    width: 0%;
  }
  .Latest-Swiper-Category-Img-Mobile {
    display: none;
  }
}/*# sourceMappingURL=LatestSwiper.css.map */
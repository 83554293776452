/* Image Group */
.Image-Wrapper {
  width: 100%;
  height: 56.25vw;
  display: flex;
  flex-flow: row nowrap;
  cursor: pointer;
}

.Main-Image {
  object-fit: cover;
  width: 56.25vw;
  height: 56.25vw;
}

.Inner-Wrapper {
  width: 43.75vw;
  height: 56.25vw;
  font-size: 0;
}

.Inner-Image {
  object-fit: cover;
  width: 100%;
  height: 50%;
}

/* hide <ImageGroup/> for pc version */
@media screen and (min-width: 1025px) {
  .Image-Wrapper {
    display: none;
  }
}

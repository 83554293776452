.Result-Content-Container {
  display: grid;
  -moz-column-gap: 30px;
       column-gap: 30px;
  row-gap: 20px;
  grid-template-columns: repeat(1, 1fr);
  padding: 3px 25px 3px 25px;
}

.Result-Article-Container {
  width: 286.6666666667;
  word-wrap: break-word;
}

@media screen and (min-width: 768px) {
  .Result-Content-Container {
    padding: 3% 3% 3% 3%;
  }
}
@media screen and (min-width: 1025px) {
  .Result-Content-Container {
    grid-template-columns: repeat(3, 1fr);
    padding: 0;
    margin-bottom: 5px !important;
  }
}
.Result-Post-Excerpt {
  font-family: MStiffHeiHK, ITC-Demi, Sans-serif;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #777;
  margin: 0;
}

@media screen and (min-width: 1025px) {
  .Result-Post-Excerpt {
    margin-bottom: 8px;
  }
}/*# sourceMappingURL=ResultPageLayout.css.map */
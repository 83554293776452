#NewsletterinputTrue,
#NewsletterinputFalse {
  font-size: 1rem;
  font-weight: normal;
  color: #303030;
  text-align: left;
  line-height: 20px;
}
.Newsletterinput {
  display: block;
}
::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #fc5901;
  opacity: 1; /* Firefox */
}
#submitbutton {
  visibility: hidden;
}

@media screen and (min-width: 0px) {
  .Newsletter-Div {
    will-change: opacity, transform;
    background-color: #242424;
    padding: 10px 15px 7px 7px;
    color: #ffffff;
    position: relative;
    width: 80%;
    height: 120px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1vh;
  }

  .Instagram-Div {
    background-image: url(../../../assets/img/subscription-01.png) !important;
    background-position: center;
    will-change: opacity, transform;
    background-color: #242424;
    padding: 10px 15px 7px 7px;
    color: #ffffff;
    position: relative;
    width: 80%;
    height: 120px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1vh;
  }

  .Newsletterinput {
    background-color: #242424;
    color: #fc5901;
    width: 75%;
    border-top: none;
    border-left: none;
    border-right: none;
    background: none;
    padding: 0px 10px 0px 10px;
    margin: 10px 10px 0px 10px;
    border-radius: 5px;
    font-family: inherit;
    font-size: 0.6em;
  }

  .NewsletterinputStatus {
    margin: 0px 0px 0px 7px;
    font-size: 5px;
    line-height: 12px;
    font-family: "PT Sans" !important;
  }

  p.Newslettertext {
    font-size: 0.6em;
    color: #ffffff;
    font-family: montserratLight;
    padding: 0px 10px 0px 10px;
    margin: 0px;
    line-height: 20px;
    text-align: left;
  }

  p.Newslettertitle {
    text-align: center;
    margin: 15px 0px 15px 0px;
    font-size: 20px;
    line-height: 0px !important;
    font-weight: bold;
    font-family: montserratBold;
  }

  .Newsletter-Div div {
    font-size: 12px;
    font-family: montserratRegular;
  }

  .Instagram-Div div {
    font-size: 12px;
    font-family: montserratRegular;
    line-height: 15px !important;
  }

  .Instagram-Image {
    object-fit: contain;
    vertical-align: middle;
    max-width: 100%;
    width: 50%;
    display: block;
    margin: 0 auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@media screen and (min-width: 768px) {
  .Newsletter-Div {
    will-change: opacity, transform;
    background-color: #242424;
    padding: 70px 30px 15px 15px;
    color: #ffffff;
    position: relative;
    width: 90%;
    height: 240px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1vh;
  }

  .Instagram-Div {
    background-image: url(../../../assets/img/subscription-01.png) !important;
    background-position: center;
    will-change: opacity, transform;
    background-color: #242424;
    padding: 70px 30px 15px 15px;
    color: #ffffff;
    position: relative;
    width: 90%;
    height: 320px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1vh;
  }

  .Newsletterinput {
    background-color: #242424;
    color: #fc5901;
    width: 75%;
    border-top: none;
    border-left: none;
    border-right: none;
    background: none;
    margin: 30px 0px 0px 140px;
    padding-bottom: 10px;
    border-radius: 5px;
    font-family: inherit;
    font-size: 10px;
    line-height: 1.15;
  }

  .NewsletterinputStatus {
    margin: 0px 0px 0px 135px;
    font-family: "PT Sans" !important;
  }

  p.Newslettertext {
    font-size: 19px;
    color: #ffffff;
    font-family: montserratLight;
    margin: 0px 0px 0px 0px;
    padding: 0% 10% 0% 15%;
    text-align: left;
    line-height: 38px;
  }

  p.Newslettertitle {
    text-align: center;
    margin: 0 auto;
    font-size: 35px;
    font-weight: bold;
    font-family: montserratBold;
  }
  .Newsletter-Div div {
    font-size: 12px;
    font-family: montserratRegular;
    line-height: 20px !important;
  }

  .Instagram-Div div {
    font-size: 12px;
    font-family: montserratRegular;
    line-height: 20px !important;
  }

  .Instagram-Image {
    height: 416px;
    object-fit: contain;
    vertical-align: middle;
    max-width: 100%;
    width: 1043px;
    aspect-ratio: auto 1043 / 1042;
    display: table;
    margin: 0 auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@media screen and (min-width: 1025px) {
  .Newsletter-Div {
    will-change: opacity, transform;
    background-color: #242424;
    padding: 70px 30px 15px 15px;
    color: #ffffff;
    position: relative;
    width: 75%;
    height: 320px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1vh;
  }

  .Instagram-Div {
    background-image: url(../../../assets/img/subscription-01.png) !important;
    background-position: center;
    will-change: opacity, transform;
    background-color: #242424;
    padding: 70px 30px 15px 15px;
    color: #ffffff;
    position: relative;
    width: 75%;
    height: 320px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1vh;
  }

  .Newsletterinput {
    background-color: #242424;
    color: #fc5901;
    width: 80%;
    border-top: none;
    border-left: none;
    border-right: none;
    background: none;
    margin: 70px 0px 0px 70px;
    padding-bottom: 10px;
    border-radius: 5px;
    font-family: inherit;
    font-size: 20px;
    line-height: 1.15;
  }

  .NewsletterinputStatus {
    margin: 0px 0px 0px 60px;
    font-family: "PT Sans" !important;
  }

  p.Newslettertext {
    font-size: 19px;
    font-family: montserratLight;
    margin: 0px 0px 0px 0px;
    padding: 30px 40px 30px 60px;
    text-align: left;
    line-height: 38px;
  }

  p.Newslettertitle {
    text-align: center;
    margin: 0 auto;
    font-size: 35px;
    font-weight: bold;
    font-family: montserratBold;
  }
  .Newsletter-Div div {
    font-size: 12px;
    font-family: montserratRegular;
    line-height: 20px !important;
  }

  .Instagram-Div div {
    font-size: 12px;
    font-family: montserratRegular;
    line-height: 20px !important;
  }

  .Instagram-Image {
    height: 416px;
    object-fit: contain;
    vertical-align: middle;
    max-width: 100%;
    width: 1043px;
    aspect-ratio: auto 1043 / 1042;
    display: table;
    margin: 0 auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@charset "UTF-8";
@font-face {
  font-family: MStiffHeiHK;
  src: url(../../../assets/font/M-Stiff-Hei-HK.woff2);
}
@media screen and (min-width: 0px) {
  .btn1, .btn2, .btn3, .btn4, .btn5, .btn6, .btn7, .btn8, .btn9, .btn10 {
    border: none;
    color: white;
    text-align: center;
    font-family: montserratLight, sans-serif;
    font-weight: 100;
    transition: transform 0.6s;
    font-weight: 100;
    background: rgba(0, 0, 0, 0);
    font-size: 20px;
    padding: 5px 41px;
    padding-right: 100px;
    opacity: 0.9;
  }
  .hair1, .hair2, .beauty1, .beauty2, .fashion1, .fashion2, .fashion3, .mind1, .mind2, .mind3, .mind4, .mind5, .living1, .living2, .living3, .living4 {
    border: none;
    color: white;
    text-align: center;
    font-family: montserratLight, sans-serif;
    font-weight: 100;
    transition: transform 0.6s;
    font-weight: 100;
    background: rgba(255, 89, 119, 0);
    padding: 3px 1px;
    font-size: 20px;
    margin: 4px 2px;
    opacity: 0.9;
  }
}
@media screen and (min-width: 600px) {
  .btn1, .btn2, .btn3, .btn4, .btn5, .btn6, .btn7, .btn8, .btn9, .btn10 {
    border: none;
    color: white;
    text-align: center;
    font-family: montserratLight, sans-serif;
    font-weight: 100;
    transition: transform 0.6s;
    font-weight: 100;
    background: rgba(0, 0, 0, 0);
    padding: 3px 30px;
    font-size: 30px;
    margin: 10px 40px;
    opacity: 0.9;
  }
  .hair1, .hair2, .beauty1, .beauty2, .fashion1, .fashion2, .fashion3, .mind1, .mind2, .mind3, .mind4, .mind5, .living1, .living2, .living3, .living4 {
    border: none;
    color: white;
    text-align: center;
    font-family: montserratLight, sans-serif;
    font-weight: 100;
    transition: transform 0.6s;
    font-weight: 100;
    background: rgba(255, 89, 119, 0);
    padding: 3px 1px;
    font-size: 30px;
    margin: 4px 20px;
    opacity: 0.9;
  }
}
@media screen and (min-width: 900px) {
  .btn1, .btn2, .btn3, .btn4, .btn5, .btn6, .btn7, .btn8, .btn9, .btn10 {
    border: none;
    color: white;
    text-align: center;
    font-family: montserratLight, sans-serif;
    font-weight: 100;
    transition: transform 0.6s;
    font-weight: 100;
    background: #1A1A1A;
    padding: 3px 8px;
    text-align: left;
    font-size: 37px;
    margin: 2px 80px;
    opacity: 0.9;
    transition: transform 0.6s;
  }
  .hair1, .hair2, .beauty1, .beauty2, .fashion1, .fashion2, .fashion3, .mind1, .mind2, .mind3, .mind4, .mind5, .living1, .living2, .living3, .living4 {
    border: none;
    color: white;
    text-align: center;
    font-family: montserratLight, sans-serif;
    font-weight: 100;
    transition: transform 0.6s;
    font-weight: 100;
    background: rgba(255, 89, 119, 0);
    white-space: nowrap;
    font-size: 37px;
    padding: 4px 40px 0px 0px;
    margin: 4px 40px 0px 0px;
    opacity: 0.9;
    transition: transform 0.6s;
  }
}
#mobileDrawerTable td {
  Padding: 3;
}

#hairSubdrawerTable, #beautySubdrawerTable, #fashionSubdrawerTable, #livingSubdrawerTable, #mindSubdrawerTable td {
  Padding: 0px;
}

.btn1:hover {
  opacity: 1;
  font-family: "MStiffHeiHK";
  transform: scale(0.9);
}

.btn1:hover:after {
  content: "主頁";
}

.btn1:hover span {
  display: none;
}

.btn2:hover {
  opacity: 1;
  font-family: "MStiffHeiHK";
  transform: scale(0.9);
}

.btn2:hover:after {
  content: "關於";
}

.btn2:hover span {
  display: none;
}

.btn3:hover {
  opacity: 1;
  font-family: "MStiffHeiHK";
  transform: scale(0.9);
}

.btn3:hover:after {
  content: "人物故事";
}

.btn3:hover span {
  display: none;
}

.btn4:hover {
  opacity: 1;
  font-family: "MStiffHeiHK";
  transform: scale(0.9);
}

.btn4:hover:after {
  content: "時裝";
}

.btn4:hover span {
  display: none;
}

.btn5:hover {
  opacity: 1;
  font-family: "MStiffHeiHK";
  transform: scale(0.9);
}

.btn5:hover:after {
  content: "美妝";
}

.btn5:hover span {
  display: none;
}

.btn6:hover {
  opacity: 1;
  font-family: "MStiffHeiHK";
  transform: scale(0.9);
}

.btn6:hover:after {
  content: "思維";
}

.btn6:hover span {
  display: none;
}

.btn7:hover {
  opacity: 1;
  font-family: "MStiffHeiHK";
  transform: scale(0.9);
}

.btn7:hover:after {
  content: "髮型";
}

.btn7:hover span {
  display: none;
}

.btn8:hover {
  opacity: 1;
  font-family: "MStiffHeiHK";
  transform: scale(0.9);
}

.btn8:hover:after {
  content: "生活";
}

.btn8:hover span {
  display: none;
}

.btn9:hover {
  opacity: 1;
  font-family: "MStiffHeiHK";
  transform: scale(0.9);
}

.btn9:hover:after {
  content: "訂閱";
}

.btn9:hover span {
  display: none;
}

.btn10:hover {
  opacity: 1;
  font-family: "MStiffHeiHK";
  transform: scale(0.9);
}

.btn10:hover:after {
  content: "聯絡";
}

.btn10:hover span {
  display: none;
}

.hair1:hover {
  opacity: 1;
  font-family: "MStiffHeiHK";
  transform: scale(0.9);
}

.hair1:hover:after {
  content: "產品";
}

.hair1:hover span {
  display: none;
}

.hair2:hover {
  opacity: 1;
  font-family: "MStiffHeiHK";
  transform: scale(0.9);
}

.hair2:hover:after {
  content: "髮型設計";
}

.hair2:hover span {
  display: none;
}

.beauty1:hover {
  opacity: 1;
  font-family: "MStiffHeiHK";
  transform: scale(0.9);
}

.beauty1:hover:after {
  content: "護膚";
}

.beauty1:hover span {
  display: none;
}

.beauty2:hover {
  opacity: 1;
  font-family: "MStiffHeiHK";
  transform: scale(0.9);
}

.beauty2:hover:after {
  content: "化妝";
}

.beauty2:hover span {
  display: none;
}

.fashion1:hover {
  opacity: 1;
  font-family: "MStiffHeiHK";
  transform: scale(0.9);
}

.fashion1:hover:after {
  content: "品牌故事";
}

.fashion1:hover span {
  display: none;
}

.fashion2:hover {
  opacity: 1;
  font-family: "MStiffHeiHK";
  transform: scale(0.9);
}

.fashion2:hover:after {
  content: "穿搭";
}

.fashion2:hover span {
  display: none;
}

.fashion3:hover {
  opacity: 1;
  font-family: "MStiffHeiHK";
  transform: scale(0.9);
}

.fashion3:hover:after {
  content: "新聞";
}

.fashion3:hover span {
  display: none;
}

.mind1:hover {
  opacity: 1;
  font-family: "MStiffHeiHK";
  transform: scale(0.9);
}

.mind1:hover:after {
  content: "電影";
}

.mind1:hover span {
  display: none;
}

.mind2:hover {
  opacity: 1;
  font-family: "MStiffHeiHK";
  transform: scale(0.9);
}

.mind2:hover:after {
  content: "書籍";
}

.mind2:hover span {
  display: none;
}

.mind3:hover {
  opacity: 1;
  font-family: "MStiffHeiHK";
  transform: scale(0.9);
}

.mind3:hover:after {
  content: "設計";
}

.mind3:hover span {
  display: none;
}

.mind4:hover {
  opacity: 1;
  font-family: "MStiffHeiHK";
  transform: scale(0.9);
}

.mind4:hover:after {
  content: "藝術";
}

.mind4:hover span {
  display: none;
}

.mind5:hover {
  opacity: 1;
  font-family: "MStiffHeiHK";
  transform: scale(0.9);
}

.mind5:hover:after {
  content: "文化";
}

.mind5:hover span {
  display: none;
}

.living1:hover {
  opacity: 1;
  font-family: "MStiffHeiHK";
  transform: scale(0.9);
}

.living1:hover:after {
  content: "飲食";
}

.living1:hover span {
  display: none;
}

.living2:hover {
  opacity: 1;
  font-family: "MStiffHeiHK";
  transform: scale(0.9);
}

.living2:hover:after {
  content: "體育";
}

.living2:hover span {
  display: none;
}

.living3:hover {
  opacity: 1;
  font-family: "MStiffHeiHK";
  transform: scale(0.9);
}

.living3:hover:after {
  content: "遊戲";
}

.living3:hover span {
  display: none;
}

.living4:hover {
  opacity: 1;
  font-family: "MStiffHeiHK";
  transform: scale(0.9);
}

.living4:hover:after {
  content: "旅遊";
}

.living4:hover span {
  display: none;
}

/*# sourceMappingURL=MenuDrawer.css.map */
$computer: true;
$click1: false;
$click2: false;

.main {
  border: 0;
  @if $click1 == false {
  }
  @if $click1 == false {
  }
  @if $click1 == false {
  }
  @if $click1 == false {
  }
  @if $click1 == false {
  }
}

.About-Page-Content {
  width: 90%;
  margin: auto;
}

.About-Page-Title-Div {
  margin: 10px 0px;
  will-change: opacity, transform;
}

.About-Eng-Div {
  will-change: opacity, transform;
  background-color: #242424;
  color: #ffffff;
  position: relative;
  z-index: 1;
}

.About-Chi-Div {
  will-change: opacity, transform;
  background-color: #fc5901;
  color: #ffffff;
  position: relative;
  margin-bottom: 30px;
}

.About-Eng-Div-Desc,
.About-Chi-Div-Desc {
  padding: 0px 60px 12px 12px;
}

@media screen and (min-width: 768px) {
  .About-Eng-Div-Desc,
  .About-Chi-Div-Desc {
    padding: 0px 46px 50px 50px;
  }
  .About-Chi-Div-Desc {
    margin-bottom: 100px;
  }
}

@media screen and (min-width: 1025px) {
  .About-Chi-Div-Desc {
    margin-bottom: 0px;
  }
}

.About-Page-Title-Div > * {
  color: #cccccc;
  margin-right: 12px;
}

.About-Page-Title-Div * {
  display: inline;
}

.About-Page-Title-Chi {
  font-family: MStiffHeiMedium, ITCMedium, Sans-serif;
  font-size: 30px;
}

.About-Page-Title-Eng {
  font-family: montserratExtraBold, ITCMedium, Sans-serif;
  font-size: 17px;
}

.About-Eng-Div-Desc div {
  font-size: 11px;
  font-family: montserratRegular;
  padding: 12px 0px;
  line-height: 1.8em !important;
}
.About-Chi-Div-Desc div {
  font-size: 11px;
  font-family: "MHei HK", ITC-Demi, Sans-serif;
  font-weight: 500;
  padding: 11px 0px;
  line-height: 1.8em !important;
}

.About-Eng-Slogan-Title {
  font-family: MontserratBold;
  padding: 12px !important;
  font-size: 20px;
  line-height: 1.6;
}

.About-Chi-Slogan-Title {
  font-family: "MHei HK", ITC-Demi, Sans-serif;
  font-weight: 900 !important;
  padding: 12px !important;
  font-size: 20px;
  line-height: 1.6;
}

@media screen and (min-width: 768px) {
  .About-Eng-Slogan-Title,
  .About-Chi-Slogan-Title {
    padding: 50px !important;
    font-size: 32px;
  }

  .About-Eng-Div-Desc div,
  .About-Chi-Div-Desc div {
    font-size: 18px;
    line-height: 36px;
  }
}
@media screen and (min-width: 1025px) {
  .About-Eng-Slogan-Title,
  .About-Chi-Slogan-Title {
    font-size: 50px !important;
  }
  .About-Eng-Div-Desc div,
  .About-Chi-Div-Desc div {
    font-size: 20px;
    line-height: 36px;
  }
}

.About-Slogan {
  font-family: montserratBold !important;
  font-style: italic;
}

.About-Chi-Div-Desc .About-Slogan {
  color: #1a1a1a;
}
@media screen and (min-width: 1025px) {
  .About-Eng-Div div {
    margin: 8px 0px;
  }
  .About-Slogan {
    font-size: 24px !important;
  }
}
.About-Hash {
  width: 20%;
  height: 156px;
  object-fit: contain;
  position: absolute;
  bottom: 0;
  right: 0;
}

@media screen and (min-width: 768px) {
  .About-Hash {
    width: 97.47px;
    height: 303.2px;
  }
}

@media screen and (min-width: 768px) {
  .About-Page-Title-Chi {
    font-size: 50px !important;
  }
  .About-Page-Title-Eng {
    font-size: 30px !important;
  }
}

@media screen and (min-width: 1025px) {
  .About-Page-Title-Div {
    margin-top: 50px;
    display: flex;
  }

  .About-Page-Title-Div * {
    margin: 0px;
  }

  .About-Page-Title-Chi {
    font-size: 56px;
    width: 14%;
  }

  .About-Page-Title-Eng {
    font-size: 34px;
    margin-top: 30px;
  }
}

@media screen and (min-width: 1025px) {
  .About-Page-Content {
    width: 100%;
    margin-top: 50px;
    height: 1160px;
    margin-bottom: 50px;
    position: relative;
  }

  .About-Eng-Div {
    box-sizing: border-box;
    position: absolute;
    width: 58%;
    top: 110px;
    left: -60px;
  }

  .About-Eng-Div div {
    font-size: 19px;
    font-weight: 500;
    line-height: 44px !important;
  }
  
  .About-Hash {
    bottom: -101px;
  }
}

@media screen and (min-width: 1025px) {
  .About-Chi-Div {
    box-sizing: border-box;
    position: absolute;
    width: 58%;
    top: 400px;
    right: -60px;
  }
}

.About-Page-Img {
  width: 100%;
  height: 403px;
  object-fit: cover;
  display: inline-block;
  border: none;
  will-change: opacity, transform;
}

@media screen and (min-width: 1025px) {
  .About-Page-Img {
    position: absolute;
    display: inline-block;
    top: 0;
    right: 0;
    width: 510px;
    padding: 20px 4px;
  }
}

@media screen and (min-width: 1025px) {
  .Only-Mobile {
    display: none;
  }
}

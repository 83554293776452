@media screen and (min-width: 768px) {
  .Not-Found-Container {
    display: flex;
    flex-direction: column;
  }
  .Not-Found-Content {
    width: 100%;
    font-size: 22px;
  }
}
@media screen and (min-width: 1025px) {
  .Not-Found-Container {
    display: flex;
    flex-direction: row;
  }
  .Not-Found-Content {
    padding-right: 3%;
    width: 50%;
    font-size: 22px;
  }
}/*# sourceMappingURL=NotFoundPage.css.map */
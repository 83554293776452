.relative-override {
  position: relative;
  z-index: 1;
}

.header-container {
  width: 100%;
  height: 47px;
  background-color: #ffffffb3;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  padding-top: 22px;
  z-index: 999;
}

.header-wrapper {
  width: 100vw;
  height: 45px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.header-logo-container {
  margin-left: 25px;
}

.header-logo {
  position: relative;
  height: 45px;
}

.header-button-list {
  display: none;
}

.header-hamburger-container {
  position: relative;
  display: flex;
  justify-content: flex-end;
  width: 92px;
  margin-right: 25px;
}

.header-hamburger {
  position: relative;
  height: 20px;
  bottom: 3px;
}

.header-button-wrapper {
  position: relative;
}

.header-button {
  position: relative;
  height: 28px;
  z-index: 1;
  left: 0;
  transition: opacity 0.15s ease-out;
}

.header-button:hover {
  opacity: 0;
}

.header-button-hover {
  height: 28px;
  position: absolute;
  width: 98%;
  left: 1px;
  cursor: pointer;
  z-index: 0;
}

@media screen and (min-width: 560px) {
  .header-hamburger {
    position: relative;
    height: 20px;
    bottom: 5px;
    cursor: pointer;
  }
}

@media screen and (min-width: 768px) {
  .header-container {
    height: 94px;
    padding-top: 0px;
  }

  .header-wrapper {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .header-logo-container {
    margin-left: 0px;
    margin-bottom: 15px;
  }

  .header-logo {
    height: 64px;
  }

  .header-hamburger-container {
    margin-right: 0px;
    margin-bottom: 15px;
  }

  .header-hamburger {
    height: 30px;
    bottom: -9px;
    cursor: pointer;
  }
}

@media screen and (min-width: 1025px) {
  .header-button-list {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    width: 597px;
    margin-bottom: 15px;
  }

  .header-container {
    width: 100%;
    height: 165px;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
  }

  .header-logo {
    position: relative;
    height: 64px;
    bottom: -12px;
  }

  .header-hamburger {
    position: relative;
    height: 30px;
    bottom: 4px;
    cursor: pointer;
  }

  .header-wrapper {
    width: 920px;
    height: 153px;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    padding-left: 1px;
  }
}

.resultPaginationContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 50px 0px;
}

.resultPaginationContainer .resultPaginationEl,
.resultPaginationContainer .activeResultPage {
  cursor: pointer;
}

.resultPaginationContainer .resultPaginationEl {
  margin: 0px 8px;
  font-family: montserratLight, "MHei HK";
  font-size: 14px;
  text-decoration: none;
}

.resultPaginationContainer .activeResultPage {
  font-family: montserratBold, "MHei HK" !important;
  text-decoration: underline;
}

@media screen and (min-width: 1025px) {
  .resultPaginationContainer .resultPaginationEl {
    font-size: 16px;
  }
}/*# sourceMappingURL=ResultPagePagination.css.map */
$mobile-content-width: 100%;
$desktop-content-width: 50%;
$not-found-font-size: 22px;

@media screen and (min-width: 768px) {
  .Not-Found-Container {
    display: flex;
    flex-direction: column;
  }
  .Not-Found-Content {
    width: $mobile-content-width;
    font-size: $not-found-font-size;
  }
}

@media screen and (min-width: 1025px) {
  .Not-Found-Container {
    display: flex;
    flex-direction: row;
  }
  .Not-Found-Content {
    padding-right: 3%;
    width: $desktop-content-width;
    font-size: $not-found-font-size;
  }
}

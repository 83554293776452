.spinner-overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: white;
  z-index: 997;
}

.spinner {
  width: 15vh;
  height: 15vh;
  display: inline-block;
  position: relative;
  left: calc(50% - 7.5vh);
  top: calc(40vh - 7.5vh);
}
.spinner::after,
.spinner::before {
  content: "";
  width: 15vh;
  height: 15vh;
  border: 2px solid rgba(46, 56, 59, 0.164);
  position: absolute;
  left: 0;
  top: 0;
  box-sizing: border-box;
  animation: rotation 2s ease-in-out infinite;
}
.spinner::after {
  border-color: #ff3d00;
  animation-delay: 0.5s;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-bar {
  display: block;
  position: relative;
  height: 25px;
  width: 200px;
  background: rgba(46, 56, 59, 0.164);
  overflow: hidden;
  left: calc(50% - 9.5vh);
  top: calc(50% - 20.5vh);
}
.loading-bar:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 0;
  background: #ff3d00;
  animation: 6s prog ease-in infinite;
}
@keyframes prog {
  to {
    width: 100%;
  }
}

.loading-word {
  font-size: 48px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  display: inline-block;
  color: #ff3d00;
  letter-spacing: 2px;
  position: relative;
  box-sizing: border-box;
  left: calc(50% - 9.5vh);
  top: calc(50% - 13.5vh);
}
.loading-word::after {
  content: "MORF";
  position: absolute;
  left: 0;
  top: 0;
  color: #fff;
  width: 100%;
  height: 100%;
  overflow: hidden;
  box-sizing: border-box;
  animation: animloader 6s linear infinite;
}

@keyframes animloader {
  0% {
    height: 100%;
  }
  100% {
    height: 0%;
  }
}

.jumping-word {
  position: absolute;
  color: #ff3d00;
  font-family: Consolas, Menlo, Monaco, monospace;
  font-weight: bold;
  font-size: 78px;
  opacity: 0.8;
  left: calc(50% - 9.5vh);
  top: calc(50% + 14.5vh);
}
.jumping-word:before {
  content: "MO";
  display: inline-block;
  animation: pulse 0.4s alternate infinite ease-in-out;
}
.jumping-word:after {
  content: "RF";
  display: inline-block;
  animation: pulse 0.4s 0.3s alternate infinite ease-in-out;
}

@keyframes pulse {
  to {
    transform: scale(0.8);
    opacity: 0.5;
  }
}

:root {
  --primary-orange: #fc5901;
  --primary-black: #414042;
  --primary-white: #e6e7e8;
  --secondary-yellow: #ffb700;
  --secondary-blue: #02a4d3;
  --secondary-pink: #ff5977;
  --secondary-purple: #ba29ba;
  --secondary-green: #9ec600;
}

.hair-color {
  color: #fc5901;
}

.people-color {
  color: #ffb700;
}

.beauty-color {
  color: #ff5977;
}

.fashion-color {
  color: #ba29ba;
}

.mind-color {
  color: #02a4d3;
}

.living-color {
  color: #9ec600;
}

.morf-color {
  color: #fc5901;
}

.people-bgcolor {
  background-color: #ffb700;
}

.hair-bgcolor {
  background-color: #fc5901;
}

.beauty-bgcolor {
  background-color: #ff5977;
}

.fashion-bgcolor {
  background-color: #ba29ba;
}

.mind-bgcolor {
  background-color: #02a4d3;
}

.living-bgcolor {
  background-color: #9ec600;
}

.morf-bgcolor {
  background-color: #fc5901;
}

body {
  background-image: url(../../assets/img/paper_bg.jpeg) !important;
  background-repeat: repeat;
  overflow: overlay;
}

.Page-Container {
  position: relative;
  min-height: 100vh;
}

.Page-Content {
  /* header height */
  padding-top: 69px;
  /* footer height */
  padding-bottom: calc(30vw + 20px + 20px);
}

/* fixed content width for large screens */
@media screen and (min-width: 1025px) {
  .Content-Container {
    margin: auto;
    width: 920px !important;
    position: relative;
  }
}
@media screen and (min-width: 768px) {
  .Page-Content {
    padding-top: 94px !important;
  }
}
@media screen and (min-width: 1025px) {
  .Page-Content {
    padding-top: 165px !important;
    padding-bottom: 403px;
  }
}
.App {
  text-align: center;
}

/* otf fonts */
@font-face {
  font-family: montserratLight;
  src: url(../../assets/font/Montserrat-Light.otf);
}
@font-face {
  font-family: montserratRegular;
  src: url(../../assets/font/Montserrat-Regular.otf);
}
@font-face {
  font-family: montserratMedium;
  src: url(../../assets/font/Montserrat-Medium.otf);
}
@font-face {
  font-family: montserratBold;
  src: url(../../assets/font/Montserrat-Bold.otf);
}
@font-face {
  font-family: montserratExtraBold;
  src: url(../../assets/font/Montserrat-ExtraBold.otf);
}
@font-face {
  font-family: ITCMedium;
  src: url(../../assets/font/ITCAvantGardeStdDemi.otf);
}
@font-face {
  font-family: ITCExtraLight;
  src: url(../../assets/font/ITCAvantGardeStdXLt.otf);
}
@font-face {
  font-family: MStiffHeiBold;
  src: url(/src/assets/font/MStiffHei-HK-Bold.ttf);
  unicode-range: -5fff, 3396dbf;
}
@font-face {
  font-family: MStiffHeiMedium;
  src: url(/src/assets/font/MStiffHei-HK-Medium.ttf);
  unicode-range: -5fff, 3396dbf;
}
/* Colored Scroll Bar */
.Dummy-Div {
  height: 20vh;
  width: 20vh;
  overflow: scroll;
  overflow-x: hidden;
  color: var(--primary-white);
  background-color: var(--primary-black);
}

.Colored-Scroll {
  scrollbar-width: thin;
}

.Colored-Scroll::-webkit-scrollbar {
  width: 8px;
}

.Colored-Scroll::-webkit-scrollbar-track {
  border-left: 3px solid white;
  border-right: 3px solid white;
}

.Colored-Scroll::-webkit-scrollbar-thumb {
  border-radius: 20px;
}

/* Firefox */
.people-scroll {
  scrollbar-color: #ffb700 transparent;
}

/* Chrome, Safari, IE, ... */
.people-scroll::-webkit-scrollbar-track,
.people-scroll::-webkit-scrollbar-thumb {
  background: #ffb700;
}

.hair-scroll {
  scrollbar-color: #fc5901 transparent;
}

.hair-scroll::-webkit-scrollbar-track,
.hair-scroll::-webkit-scrollbar-thumb {
  background: #fc5901;
}

.beauty-scroll {
  scrollbar-color: #ff5977 transparent;
}

.beauty-scroll::-webkit-scrollbar-track,
.beauty-scroll::-webkit-scrollbar-thumb {
  background: #ff5977;
}

.fashion-scroll {
  scrollbar-color: #ba29ba transparent;
}

.fashion-scroll::-webkit-scrollbar-track,
.fashion-scroll::-webkit-scrollbar-thumb {
  background: #ba29ba;
}

.mind-scroll {
  scrollbar-color: #02a4d3 transparent;
}

.mind-scroll::-webkit-scrollbar-track,
.mind-scroll::-webkit-scrollbar-thumb {
  background: #02a4d3;
}

.living-scroll {
  scrollbar-color: #9ec600 transparent;
}

.living-scroll::-webkit-scrollbar-track,
.living-scroll::-webkit-scrollbar-thumb {
  background: #9ec600;
}

.Title-Font {
  font-family: montserratBold, MStiffHeiBold;
}

.Article-Title-Container .Text-Column-desktop {
  scrollbar-width: none;
}/*# sourceMappingURL=Common.css.map */
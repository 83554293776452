// Definition of the CSS variables with value defined
$z-index-group: (
  "top": 999,
  "medium": 1,
  "bottom": -1,
);
$backdrop-background: rgba(0, 0, 0, 0.9);
$drawer-background: rgb(255, 255, 255);
$transition-speed: 0.3s;
$full-width: 100%;
$full-height: 100%;
$zero-width: 0%;
$zero-height: 0%;
$full-opacity: 1;

// Common Color and configuration
$color-set: (
  "primary-orange": #fc5901,
  "primary-black": #414042,
  "primary-white": #e6e7e8,
  "secondary-yellow": #ffb700,
  "secondary-blue": #02a4d3,
  "secondary-pink": #ff5977,
  "secondary-purple": #ba29ba,
  "secondary-green": #9ec600,
);
:root {
  --primary-orange: #fc5901;
  --primary-black: #414042;
  --primary-white: #e6e7e8;
  --secondary-yellow: #ffb700;
  --secondary-blue: #02a4d3;
  --secondary-pink: #ff5977;
  --secondary-purple: #ba29ba;
  --secondary-green: #9ec600;
}

.hair-color {
  color: map-get($color-set, "primary-orange");
}

.people-color {
  color: map-get($color-set, "secondary-yellow");
}

.beauty-color {
  color: map-get($color-set, "secondary-pink");
}

.fashion-color {
  color: map-get($color-set, "secondary-purple");
}

.mind-color {
  color: map-get($color-set, "secondary-blue");
}

.living-color {
  color: map-get($color-set, "secondary-green");
}

.morf-color {
  color: map-get($color-set, "primary-orange");
}

.people-bgcolor {
  background-color: map-get($color-set, "secondary-yellow");
}

.hair-bgcolor {
  background-color: map-get($color-set, "primary-orange");
}

.beauty-bgcolor {
  background-color: map-get($color-set, "secondary-pink");
}

.fashion-bgcolor {
  background-color: map-get($color-set, "secondary-purple");
}

.mind-bgcolor {
  background-color: map-get($color-set, "secondary-blue");
}

.living-bgcolor {
  background-color: map-get($color-set, "secondary-green");
}

.morf-bgcolor {
  background-color: map-get($color-set, "primary-orange");
}

body {
  // background-image: url('../../assets/img/paper_bg.jpg');
  background-image: url(../../assets/img/paper_bg.jpeg) !important;
  background-repeat: repeat;
  overflow: overlay;
}

.Page-Container {
  position: relative;
  min-height: 100vh;
}

.Page-Content {
  /* header height */
  padding-top: 69px;
  /* footer height */
  padding-bottom: calc(30vw + 20px + 20px);
}

/* fixed content width for large screens */
@media screen and (min-width: 1025px) {
  .Content-Container {
    margin: auto;
    width: 920px !important;
    position: relative;
  }
}

@media screen and (min-width: 768px) {
  .Page-Content {
    padding-top: 94px !important;
  }
}

@media screen and (min-width: 1025px) {
  .Page-Content {
    padding-top: 165px !important;
    padding-bottom: 403px;
  }
}

.App {
  text-align: center;
}
// Font
/* otf fonts */
@font-face {
  font-family: montserratLight;
  src: url(../../assets/font/Montserrat-Light.otf);
}

@font-face {
  font-family: montserratRegular;
  src: url(../../assets/font/Montserrat-Regular.otf);
}

@font-face {
  font-family: montserratMedium;
  src: url(../../assets/font/Montserrat-Medium.otf);
}

@font-face {
  font-family: montserratBold;
  src: url(../../assets/font/Montserrat-Bold.otf);
}

@font-face {
  font-family: montserratExtraBold;
  src: url(../../assets/font/Montserrat-ExtraBold.otf);
}

@font-face {
  font-family: ITCMedium;
  src: url(../../assets/font/ITCAvantGardeStdDemi.otf);
}

@font-face {
  font-family: ITCExtraLight;
  src: url(../../assets/font/ITCAvantGardeStdXLt.otf);
}

@font-face {
  font-family: MStiffHeiBold;
  src: url(/src/assets/font/MStiffHei-HK-Bold.ttf);
  unicode-range: 4-9fff, 3400-4dbf;
}

@font-face {
  font-family: MStiffHeiMedium;
  src: url(/src/assets/font/MStiffHei-HK-Medium.ttf);
  unicode-range: 4-9fff, 3400-4dbf;
}

// Scroll Bar

/* Colored Scroll Bar */
.Dummy-Div {
  height: 20vh;
  width: 20vh;
  overflow: scroll;
  overflow-x: hidden;
  color: var(--primary-white);
  background-color: var(--primary-black);
}

.Colored-Scroll {
  scrollbar-width: thin;
}

.Colored-Scroll::-webkit-scrollbar {
  width: 8px;
}

.Colored-Scroll::-webkit-scrollbar-track {
  border-left: 3px solid white;
  border-right: 3px solid white;
}

.Colored-Scroll::-webkit-scrollbar-thumb {
  border-radius: 20px;
}

/* Firefox */
// Firefox has some limitation on scrollbar style, so cannot achieve the same result as other browsers.
.people-scroll {
  scrollbar-color: map-get($color-set, "secondary-yellow") transparent;
}

/* Chrome, Safari, IE, ... */
.people-scroll::-webkit-scrollbar-track,
.people-scroll::-webkit-scrollbar-thumb {
  background: map-get($color-set, "secondary-yellow");
}

.hair-scroll {
  scrollbar-color: map-get($color-set, "primary-orange") transparent;
}

.hair-scroll::-webkit-scrollbar-track,
.hair-scroll::-webkit-scrollbar-thumb {
  background: map-get($color-set, "primary-orange");
}

.beauty-scroll {
  scrollbar-color: map-get($color-set, "secondary-pink") transparent;
}

.beauty-scroll::-webkit-scrollbar-track,
.beauty-scroll::-webkit-scrollbar-thumb {
  background: map-get($color-set, "secondary-pink");
}

.fashion-scroll {
  scrollbar-color: map-get($color-set, "secondary-purple") transparent;
}

.fashion-scroll::-webkit-scrollbar-track,
.fashion-scroll::-webkit-scrollbar-thumb {
  background: map-get($color-set, "secondary-purple");
}

.mind-scroll {
  scrollbar-color: map-get($color-set, "secondary-blue") transparent;
}

.mind-scroll::-webkit-scrollbar-track,
.mind-scroll::-webkit-scrollbar-thumb {
  background: map-get($color-set, "secondary-blue");
}

.living-scroll {
  scrollbar-color: map-get($color-set, "secondary-green") transparent;
}

.living-scroll::-webkit-scrollbar-track,
.living-scroll::-webkit-scrollbar-thumb {
  background: map-get($color-set, "secondary-green");
}

.Title-Font {
  font-family: montserratBold, MStiffHeiBold;
}

.Article-Title-Container .Text-Column-desktop {
  scrollbar-width: none;
}
@charset "UTF-8";
$menu-btn-font: "MStiffHeiHK";
$btn-names: btn1, btn2, btn3, btn4, btn5, btn6, btn7, btn8, btn9, btn10, hair1, hair2, beauty1, beauty2, fashion1, fashion2, fashion3, mind1, mind2, mind3, mind4, mind5, living1, living2, living3, living4;
$btn-content: "主頁", "關於", "人物故事", "時裝", "美妝", "思維", "髮型", "生活", "訂閱", "聯絡", "產品", "髮型設計", "護膚","化妝","品牌故事","穿搭", "新聞", "電影", "書籍", "設計", "藝術", "文化", "飲食", "體育", "遊戲", "旅遊";
$click-once: false;
$click-twice: false;

@font-face {
  font-family: MStiffHeiHK;
  src: url(../../../assets/font/M-Stiff-Hei-HK.woff2);
}

@mixin check-click($is-click) {
  
  $click-once: true;
  $click-twice: false;
}

@mixin drawer-style() {
  border: none;
  color: white;
  text-align: center;
  font-family: montserratLight, sans-serif;
  font-weight: 100;
  transition: transform 0.6s;
  font-weight: 100;
}

@media screen and (min-width: 0px) {
  .btn1, .btn2, .btn3, .btn4, .btn5, .btn6, .btn7, .btn8, .btn9, .btn10 {
    @include drawer-style(); 
    background: rgba(0, 0, 0, 0);
    font-size: 20px;
    padding: 5px 41px;
    padding-right: 100px;
    opacity: 0.9;
  }
  
  .hair1, .hair2, .beauty1, .beauty2, .fashion1, .fashion2, .fashion3, .mind1, .mind2, .mind3, .mind4, .mind5, .living1, .living2, .living3, .living4 {
    @include drawer-style();
    background: rgba(255, 89, 119, 0);
    padding: 3px 1px;
    font-size: 20px;
    margin: 4px 2px;
    opacity: 0.9;
  }
}
@media screen and (min-width: 600px) {
  .btn1, .btn2, .btn3, .btn4, .btn5, .btn6, .btn7, .btn8, .btn9, .btn10 {
    @include drawer-style();
    background: rgba(0, 0, 0, 0);
    padding: 3px 30px;
    font-size: 30px;
    margin: 10px 40px;
    opacity: 0.9;
  }
  .hair1, .hair2, .beauty1, .beauty2, .fashion1, .fashion2, .fashion3, .mind1, .mind2, .mind3, .mind4, .mind5, .living1, .living2, .living3, .living4 {
    @include drawer-style();
    background: rgba(255, 89, 119, 0);
    padding: 3px 1px;
    font-size: 30px;
    margin: 4px 20px;
    opacity: 0.9;
  }
}
@media screen and (min-width: 900px) {
  .btn1, .btn2, .btn3, .btn4, .btn5, .btn6, .btn7, .btn8, .btn9, .btn10 {
    @include drawer-style();
    background: #1A1A1A;
    padding: 3px 8px;
    text-align: left;
    font-size: 37px;
    margin: 2px 80px;
    opacity: 0.9;
    transition: transform .6s;
  }
  .hair1, .hair2, .beauty1, .beauty2, .fashion1, .fashion2, .fashion3, .mind1, .mind2, .mind3, .mind4, .mind5, .living1, .living2, .living3, .living4 {
    @include drawer-style();
    background: rgba(255, 89, 119, 0);
    white-space: nowrap;
    font-size: 37px;
    padding: 4px 40px 0px 0px;
    margin: 4px 40px 0px 0px;
    opacity: 0.9;
    transition: transform .6s;
  }
}

#mobileDrawerTable td{
Padding:3;
}
#hairSubdrawerTable,#beautySubdrawerTable,#fashionSubdrawerTable,#livingSubdrawerTable,#mindSubdrawerTable td{
Padding:0px;
}

@each $btn-name in $btn-names {
  $i: index($btn-names, $btn-name);
  .#{$btn-name}:hover {
    opacity: 1;
    font-family: $menu-btn-font;
    transform: scale(0.9);
  }
  .#{$btn-name}:hover:after {
    content: nth($btn-content, $i);
  }
  .#{$btn-name}:hover span {
    display: none;
  }

}



/*# sourceMappingURL=MenuDrawer.css.map */
.Latest-Excerpt-Readmore {
  width: 60%;
}

.Latest-Excerpt {
  padding-top: 10px;
  padding-right: 20px;
  margin: 0;
  font-family: montserratLight;
  font-weight: 300;
  font-size: 12px;
  color: #303030;
}

.First-Four-Lines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  -webkit-box-orient: vertical;
}

.Read-More {
  font-family: montserratMedium;
  font-style: italic;
  font-size: 13px;
  font-weight: 300 !important;
  text-decoration: underline;
}

@media screen and (min-width: 1025px) {
  .Latest-Excerpt-Readmore {
    width: 50%;
    background-color: #ffffff;
    opacity: 70%;
  }

  .Latest-Excerpt {
    font-size: 16px !important;
    font-family: montserratRegular;
    /* ,MStiffHeiMedium */
    font-weight: 400;
  }

  .Read-More {
    font-size: 16px;
    font-weight: 500 !important;
  }
}

.Category-Bar {
  height: 20px;
  padding: 15px 10px;
  max-width: 100%;
}

.Category-Icon-Mobile {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: start;
  width: 100%;
  height: 100%;
}

@media screen and (min-width: 768px) {
  .Category-Bar {
    height: 45px;
    padding: 30px 40px;
  }
}

@media screen and (min-width: 1025px) {
  .Category-Bar {
    display: none !important;
  }
}

.Category-Excerpt-Readmore {
  padding: 12px 25px;
}

@media screen and (min-width: 1025px) {
  .Category-Excerpt-Readmore .Category-Title {
    display: none;
  }

  .Category-Excerpt-Readmore {
    padding: 0px 8px;
  }
}

.Category-Title {
  font-size: 20px;
  padding-bottom: 3px;
  cursor: pointer;
}

a.Category-Title {
  margin: 8px;
}

.Category-Excerpt-Desc {
  font-family: montserratLight, "MHei HK";
  font-size: 13px;
  font-weight: 300;
  color: #303030;
  line-height: 1.6;
}

.Category-Sections {
  padding: 0px 10px;
  display: none !important;
}

.Category-Sections hr {
  margin: 20px 0px;
}

.Category-Sections hr.Tall-Hr {
  margin-top: 58px;
}

.Category-Section {
  width: 900px;
  height: 540px;
  /* height: 1500px; */
  margin: auto;
}

.Category-Section-Content {
  width: 800px;
  margin: auto;
  height: 100%;
}

.Category-Section-Icon {
  width: 100%;
  height: 35px;
  padding: 12px 0px;
  /* margin: auto !important; */
}

.Section-Bottom-Border {
  border-bottom-color: #999999 !important;
  border-bottom-style: solid;
  border-width: 1px !important;
}

.Section-Right-Border {
  border-right-color: #999999 !important;
  border-right-style: solid;
  border-width: 1px !important;
}

.Category-Icon {
  width: auto;
  height: 100%;
  padding: 0px 12px 12px 0px;
}

.Category-Section-Imgs-Layout {
  overflow: scroll;
  overflow-x: hidden;
  margin: auto !important;
  margin-top: 11px !important;
  padding: 0px 5px;
}

.Category-Section-Imgs-Layout-Small {
  height: 440px;
}

.Category-Section-Imgs-Layout-Large {
  height: 482px;
}

.Category-Section-Imgs-Layout a {
  text-decoration: none;
}

.Category-Section-Img {
  width: 100%;
  margin: 8px;
}

@media screen and (min-width: 1025px) {
  .Category-Title {
    margin: 6px 8px;
  }

  .Category-Sections {
    margin-top: 40px;
    display: block !important;
  }
}

.people-Category-Swiper-Custom-Pagination-Wrapper,
.hair-Category-Swiper-Custom-Pagination-Wrapper,
.beauty-Category-Swiper-Custom-Pagination-Wrapper,
.fashion-Category-Swiper-Custom-Pagination-Wrapper,
.mind-Category-Swiper-Custom-Pagination-Wrapper,
.living-Category-Swiper-Custom-Pagination-Wrapper {
  height: 20px;
  padding: 15px 10px;
  width: auto;
  position: absolute;
  top: 0;
  right: 0;
}

.people-Category-Swiper-Custom-Pagination,
.hair-Category-Swiper-Custom-Pagination,
.beauty-Category-Swiper-Custom-Pagination,
.fashion-Category-Swiper-Custom-Pagination,
.mind-Category-Swiper-Custom-Pagination,
.living-Category-Swiper-Custom-Pagination {
  width: auto !important;
  height: auto !important;
  text-align: right;
}

@media screen and (min-width: 768px) {
  .people-Category-Swiper-Custom-Pagination-Wrapper,
  .hair-Category-Swiper-Custom-Pagination-Wrapper,
  .beauty-Category-Swiper-Custom-Pagination-Wrapper,
  .fashion-Category-Swiper-Custom-Pagination-Wrapper,
  .mind-Category-Swiper-Custom-Pagination-Wrapper,
  .living-Category-Swiper-Custom-Pagination-Wrapper {
    height: auto;
    padding: 42px 40px;
  }
}

@media screen and (min-width: 1025px) {
  .people-Category-Swiper-Custom-Pagination-Wrapper,
  .hair-Category-Swiper-Custom-Pagination-Wrapper,
  .beauty-Category-Swiper-Custom-Pagination-Wrapper,
  .fashion-Category-Swiper-Custom-Pagination-Wrapper,
  .mind-Category-Swiper-Custom-Pagination-Wrapper,
  .living-Category-Swiper-Custom-Pagination-Wrapper {
    display: none;
  }
}

.Category-Section-Two-Col-Layout {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow: hidden !important;
}

.First-Item-Div {
  height: 464px;
  flex-basis: 60%;
  margin: 4px;
}

.Other-Item-Div {
  height: 464px;
  flex-basis: 40%;
  margin: 4px;
  // padding-left: 20px;
  overflow: scroll;
  overflow-x: hidden;
}

.Issue-Container {
  font-family: ITCMedium;
}

.Article-Text-Content {
  font-family: montserratMedium, "MHei HK" !important;
}

.Hash-Bg {
  background-image: none;
  position: absolute;
  margin-top: -60px;
  top: 0;
  left: 0;
  right: 0;
}

@media screen and (min-width: 1025px) {
  .First-Item-Div .landing-card-title {
    font-size: 24px;
  }

  .First-Item-Div .Category-Excerpt-Desc {
    font-family: montserratRegular, "MHei HK";
    font-size: 15px;
    line-height: 1.6;
  }

  .Left-Scroll {
    padding-left: 20px;
    direction: rtl;
  }
  .Right-Scroll {
    padding-right: 20px;
    direction: ltr;
  }
  .Hash-Bg-Container {
    height: 740px;
  }
  .Hash-Bg {
    height: 1000px;
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center top;
    will-change: opacity;
    overflow: hidden;
  }
  .People-Hash {
    background-image: url("../../assets/img/people-hash.png");
    opacity: 0%;
  }
  .Hair-Hash {
    background-image: url("../../assets/img/hair-hash.png");
    opacity: 0%;
  }
  .Beauty-Hash {
    background-image: url("../../assets/img/beauty-hash.png");
    opacity: 0%;
  }
  .Fashion-Hash {
    background-image: url("../../assets/img/fashion-hash.png");
    opacity: 0%;
  }
  .Mind-Hash {
    background-image: url("../../assets/img/mind-hash.png");
    opacity: 0%;
  }
  .Living-Hash {
    background-image: url("../../assets/img/living-hash.png");
    opacity: 0%;
  }
}

.Category-Section-Two-Row-Layout {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

.Contact-Page-Content {
  width: 90%;
  margin: auto;
}

.Contact-Page-Title-Div {
  margin: 10px 0px;
  will-change: opacity, transform;
}

.Contact-Map-Div {
  will-change: opacity, transform;
}

.Contact-Info-Div {
  will-change: opacity, transform;
  background-color: #242424;
  padding: 25px 0px 25px 25px;
  color: #ffffff;
  position: relative;
  margin-bottom: 30px;
}

@media screen and (min-width: 768px) {
  .Contact-Info-Div {
    padding: 78px 0px 78px 78px;
    margin-bottom: 100px;
  }
}
.Contact-Page-Title-Div > * {
  color: #cccccc;
  margin-right: 12px;
}

.Contact-Page-Title-Div * {
  display: inline;
}

.Contact-Page-Title-Chi {
  font-family: MStiffHeiMedium, ITCMedium, Sans-serif;
  font-size: 30px;
}

.Contact-Page-Title-Eng {
  font-family: montserratExtraBold, ITCMedium, Sans-serif;
  font-size: 17px;
}

.Contact-Info-Div div {
  font-size: 12px;
  font-family: montserratRegular;
  line-height: 20px !important;
}

.Contact-Info-Slogan {
  font-family: montserratBold !important;
  font-style: italic;
}

@media screen and (min-width: 1025px) {
  .Contact-Info-Div div {
    margin: 8px 0px;
  }
  .Contact-Info-Slogan {
    font-size: 24px;
  }
}
.Contact-Info-Hash {
  width: 20%;
  height: 156px;
  -o-object-fit: contain;
     object-fit: contain;
  position: absolute;
  bottom: 0;
  right: 0;
}

.Contact-Map {
  width: 100%;
  height: 200px;
}

@media screen and (min-width: 768px) {
  .Contact-Info-Hash {
    width: 97.47px;
    height: 303.2px;
  }
  .Contact-Map {
    width: 100%;
    height: 531px;
  }
}
@media screen and (min-width: 1025px) {
  .Contact-Map {
    height: 662px;
  }
}
@media screen and (min-width: 768px) {
  .Contact-Page-Title-Chi {
    font-size: 50px !important;
  }
  .Contact-Page-Title-Eng {
    font-size: 30px !important;
  }
}
@media screen and (min-width: 1025px) {
  .Contact-Page-Title-Div {
    margin-top: 50px;
    display: flex;
  }
  .Contact-Page-Title-Div * {
    margin: 0px;
  }
  .Contact-Page-Title-Chi {
    font-size: 56px;
    width: 69%;
    text-align: right;
  }
  .Contact-Page-Title-Eng {
    font-size: 34px;
    width: 30.911%;
    margin: auto;
    margin-top: 30px;
  }
}
@media screen and (min-width: 1025px) {
  .Contact-Page-Content {
    width: 100%;
    margin-top: 50px;
    height: 1028px;
    margin-bottom: 50px;
    position: relative;
  }
  .Contact-Map-Div {
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    margin: 0% 50% 0% 0%;
  }
  .Contact-Info-Div {
    box-sizing: border-box;
    position: absolute;
    width: 55%;
    margin: 0% 0% 0% 45%;
    padding: 78px 78px 350px 78px;
  }
  .Contact-Info-Div div {
    font-size: 19px;
    font-weight: 500;
    line-height: 44px !important;
  }
  .Contact-Info-Hash {
    bottom: -101px;
  }
}
.Contact-Page-Morf-Slogan {
  display: none;
}

@media screen and (min-width: 1025px) {
  .Contact-Page-Morf-Slogan {
    position: absolute;
    display: inline-block;
    width: 72%;
    bottom: 0;
    left: 0;
    padding: 20px;
  }
}/*# sourceMappingURL=Contact.css.map */